import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../atoms/button";
import { Workcarousel } from "../../organisms/aboutcarousel";
import about_img1 from "../../assets/images/about_asset1.png";
import about_img2 from "../../assets/images/mindblocks.png";
import about_img3 from "../../assets/images/Asset2.png";
import about_img4 from "../../assets/images/partnership.png";
import about_img6 from "../../assets/images/untabooers.png";
import anju from "../../assets/images/aboutus_dialouge.png";
import video_bg from "../../assets/images/aboutus_thumbnail.png";
import ana from "../../assets/images/Ana.jpg";
import about_img9 from "../../assets/images/advisory2.png";
import ratika from "../../assets/images/Ratika.png";
import about_img11 from "../../assets/images/advisory4.png";
import quote from "../../assets/images/anju_quote.png";
import ModalVideo from "react-modal-video";
import { Container } from "react-bootstrap";
import Mvideo from "../../atoms/ModalVideo";

export const About = (props) => {
  document.title = "About";
  const [isOpen, setOpen] = useState(false);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div id={"whatWeDo"} className="bg-img-about poppins">
      <Container className="w-100 pb-5 ">
        <div className="d-lg-flex align-items-center justify-content-between">
          <div className="col-lg-6 px-4 px-lg-0 col-12 pt-10 ">
            <h1
              className="pt-5 popins-700 f-55 f-sm-38"
              style={{ fontWeight: "700", color: "#1e4363" }}
            >
              <span className="primary-color ">UnTaboo</span> <br></br>
              is a Safety & Sex Education Company
            </h1>
            <h3 className="mt-4  poppins-700">
              With over a decade of experience
            </h3>
            <p className="mt-4 mb-5 lh-4 fs-5 poppins-400 ">
              We <b>teach children</b> and teens the skills necessary to
              <b> navigate</b> the tricky <b>growing up years with ease</b>,
              helping them transition into adulthood safely.
            </p>
          </div>
          <div className="col-lg-5 px-4 px-lg-0 col-12 pt-lg-5  position-relative">
            <img
              className="w-100 "
              src={video_bg}
              alt={"video_img"}
              style={{
                borderRadius: "20px",
                boxShadow: " rgb(0 0 0 / 20%) 0px 3px 10px",
              }}
            />
            <Mvideo
              channel="custom"
              isOpen={isOpen}
              url="https://api.untaboo.online/uploads/FILM_20_Untaboo_About.m3u8"
              onClose={() => setOpen(false)}
            />
            {/* <div style={{position: 'absolute' ,top:'0',left:'30%'}}>
              <video autoplay isOpen={isOpen}  onClose={() => setOpen(false)} src={video} width="750" height="450" controls></video>
              </div> */}
            <button
              onClick={() => setOpen(true)}
              className="video-play blue_shadow rounded-circle position-absolute  d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#6BCACF",
                width: "80px",
                height: "80px",
                left: "43%",
                top: "43%",
              }}
            >
              <div
                className=""
                style={{
                  width: "0",
                  height: " 0",
                  borderTop: "12px solid transparent",
                  borderBottom: "12px solid transparent",
                  borderLeft: "12px solid white",
                }}
              ></div>
            </button>
          </div>
        </div>

        <div className="mt-15">
          <div className="d-flex flex-lg-row flex-column-reverse justify-content-center">
            <div className="col-lg-6 text-start mb-2 px-4 px-lg-0">
              <img
                className="col-lg-9 col-12 h-100"
                src={about_img1}
                alt="aboutus_asset1"
              />
            </div>
            <div
              className="px-4 px-lg-0 col-lg-6 col-sm-12 fs-5"
              style={{ color: "#656565" }}
            >
              <h5 className="mt-lg-5 mt-4 primary-blue f-40">
                <b style={{ color: "#005995" }}>Navigating Teenagehood</b>
              </h5>
              <p className="fs-5 mb-3 mb-lg-0" style={{ lineHeight: "1.7" }}>
                We educate and empower understanding on{" "}
                <b>
                  Body safety I Puberty I Sex Education I Healthy Relationships
                  I Drug safety I Online safety I Appropriate Cyber behaviour
                </b>
                , and other topics to nurture a positively informed, happier &
                responsible generation, leading to a more safer society.
              </p>
              <h5 className="mt-lg-5 mt-3 f-40 primary-blue">
                <b style={{ color: "#005995" }}>
                  Engaging & change driven programs
                </b>
              </h5>
              <p className="fs-5 mb-3 mb-lg-0" style={{ lineHeight: "1.7" }}>
                We use creative tools to impart Safety & Sex Education in the
                most child-friendly and relatable way to ensure engagement,
                understanding, retention, and impact. In fact, we are the
                <b className="secondary-b"> only company in the world </b> using
                multiple formats to impart this Education
              </p>
              <h5 className="mt-lg-5 mt-3 primary-blue f-40">
                <b style={{ color: "#005995" }}>
                  The Pioneer in Sex Ed for Teens
                </b>
              </h5>
              <p className="fs-5 mb-3 mb-lg-0" style={{ lineHeight: "1.7" }}>
                We are the
                <b className="secondary-b"> only company in the world </b> using
                multiple formats to impart Safety & Sex Education. And we are
                <b className="secondary-b">
                  {" "}
                  INDIA’S 1ST ONLINE GAMIFIED SAFETY & SEX ED PLATFORM
                </b>
                &nbsp; equipping Pre-teens & Teens with the skills necessary to
                navigate the tricky growing up years with ease, helping them
                transition to adulthood safely.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="my-5 col-9 mx-auto text-center ">
          <h2 className="primary-blue pb-2">
            INDIA’S 1ST ONLINE GAMIFIED SAFETY & SEX ED PLATFORM
          </h2>
          <p className=" fs-4 Yanone_Kaffeesatz secondary-b">
            We equip pre-teens & teens with the skills necessary to navigate the
            tricky growing up years with ease, helping them transition into
            adulthood safely.
          </p>
        </div> */}

        <div className="mt-10">
          <div className="px-4 px-lg-0 d-flex flex-lg-row flex-column justify-content-center">
            <div className=" col-lg-6 fs-5">
              {/* <h3 className="">END THE TABOOS - START THE CONVERSATION</h3> */}
              <h5 className="mt-lg-5 mt-3 primary-blue f-40">
                <b style={{ color: "#005995" }}>
                  UnTaboo has embarked on a mission…
                </b>
              </h5>
              <p style={{ lineHeight: "1.7" }}>
                ...to change the mindsets around Safety & Sex Education & make
                it acceptable and accessible to all.<br></br>
                <br></br>
                …to deliver accurate, age-appropriate content & help the youth
                grow up with safe & responsible attitudes.<br></br>
                <br></br>
                ...to help open conversations at home & schools leading to a
                healthier bond between children, Parents & educators.
              </p>
            </div>
            <div className="col-lg-6 text-center mb-5">
              <img
                className="col-lg-6 col-12 h-100"
                src={about_img2}
                alt="mindblocks"
              />
            </div>
          </div>
          <div className="px-4 px-lg-0 col-12 text-center mx-auto mt-lg-5 mt-3 ">
            <Link to="/contact">
              <Button className="mx-auto">
                END THE TABOOS - START THE CONVERSATION
              </Button>
            </Link>
          </div>
        </div>
      </Container>

      <Container>
        <div className=" col-11 mx-auto mt-10 ">
          <h3 className="primary-blue f-40">
            <b>Our Impact</b>{" "}
          </h3>
        </div>
        <p
          className="mt-lg-5 mt-3 pb-2 fs-5 col-11 mx-auto"
          style={{ color: "#656565" }}
        >
          We have directly touched the lives of{" "}
          <b className="secondary-b">100K+ children, parents & educators.</b>{" "}
          However the impact of our programs has a ripple effect and changes the
          lives of more people than we can factor in. We continue to reach &
          teach, stepping closer to our mission of transformation: one child -
          one family- one school at a time.{" "}
        </p>
        <div className="text-center ">
          <img
            className="col-11 mt-lg-5 mt-3 text-align-start"
            src={about_img3}
          />
        </div>

        <div className="col-11 mx-auto mt-10">
          <h3 className="primary-blue f-40">
            <b>Our Partnerships</b>{" "}
          </h3>
        </div>
        <p
          className="col-11 mx-auto mt-lg-5 mt-3 fs-5"
          style={{ color: "#656565" }}
        >
          We have <b className="secondary-b">partnered with </b>
          individuals, schools, corporates, brands, organisations, NGO’s &
          philanthropic agencies that have taken progressive steps to normalise
          conversations on these sensitive topics to enable healthy life skills
          development.{" "}
        </p>
        <div className="text-center">
          <img className="col-11 mt-lg-5 mt-3 " src={about_img4} />
        </div>
      </Container>

      <div
        className="mt-10 pt-10 px-4 px-lg-0"
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <Container className="d-flex flex-wrap align-items-center space justify-content-between">
          <div className="col-12 mb-5">
            <h3 className="f-40">
              <b className="primary-blue">PARTNER WITH US</b>
            </h3>
          </div>
          <div className="col-lg-5  col-md-12 col-12 d-flex flex-wrap  justify-content-between">
            <div
              className="w-40 w-sm-45 text-center  pt-3"
              style={{
                backgroundColor: "#6BCACF",
                color: "#fff",
                fontWeight: "600",
              }}
            >
              <p>Parents</p>
            </div>
            <div
              className="w-40 w-sm-45 text-center  pt-3"
              style={{
                backgroundColor: "#6BCACF",
                color: "#fff",
                fontWeight: "600",
              }}
            >
              <p>Schools</p>
            </div>
            <div
              className="w-40 w-sm-45 text-center mt-4 pt-3"
              style={{
                backgroundColor: "#6BCACF",
                color: "#fff",
                fontWeight: "600",
              }}
            >
              <p>Corporates</p>
            </div>
            <div
              className="w-40 w-sm-45 text-center mt-4 pt-3"
              style={{
                backgroundColor: "#6BCACF",
                color: "#fff",
                fontWeight: "600",
              }}
            >
              <p>Organisations</p>
            </div>
          </div>
          <div className="col-lg-6 fs-5  ">
            <p
              className="mt-lg-0 mt-5"
              style={{ color: "#656565", lineHeight: "1.7" }}
            >
              Bring UnTaboo into the lives of your children, your employees and
              their children, your consumers or even to sponsor these programs
              for children or organizations that do not have ready access to
              this kind of education.
            </p>
          </div>
        </Container>
      </div>

      <div
        className=" px-4 px-lg-0 pt-5  pb-10"
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <Container className="text-center mt-lg-5 mt-3">
          <Link to="/contact">
            <Button>Reach out to us</Button>
          </Link>
          <h3 className="mt-10  secondary-blue f-35">
            <b>
              Help raise a responsible generation leading to a safer society
            </b>
          </h3>
          <h3 className="h3 mt-4 primary-color">
            <b>Because when we talk – we educate</b>
          </h3>
          <h3 style={{ color: "#379FBF" }}>
            <b>When we educate – we create responsibility</b>
          </h3>
          <h3 style={{ color: "#1D7AB3" }}>
            <b>When we create responsibility – change happens</b>
          </h3>
          {/* <h2 className="mt-lg-5 mt-3 primary-blue fs-1">
            <b>Be a part of the change – Be an Untabooer!</b>
          </h2>
          <h3>
            <b>Make safety & sex education a part of your child’s life</b>
          </h3> */}
        </Container>
      </div>

      <Container className="text-center py-5">
        <img className="w-100" src={about_img6} />
      </Container>

      <div
        id={"knowUs"}
        className="mt-lg-5  mt-3 pt-10 pb-10"
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <Container className="d-flex flex-wrap justify-content-between">
          {/* <div className="d-md-flex  d-lg-flex d-none w-50 w-sm-100  flex-column justify-content-end align-items-end pb-4 position-relative">
            <div className="cloudBg ">
              {" "}
              <p>
                <i>
                  "I envision an India where safety & sex education is
                  recognised as an important life skill for a child’s holistic
                  development; where parents and educators have open and honest
                  conversations on puberty, sex, sexuality, relationships,
                  substance abuse & safety."
                </i>
              </p>{" "}
            </div>

            <div className="cloud_2Bg">
              {" "}
              <p>
                <i>
                  "Children must grow up with positive information, healthy
                  attitudes, & better decision-making skills - all leading to a
                  responsible generation and safer society at large.”
                </i>
                <br></br>
              </p>{" "}
            </div>
            
            <img className="w-45 w-sm-40 pr-20" src={about_img7} />
          </div> */}
          <div className="d-none d-md-flex">
            <img className="pt-3 mt-4" src={anju} alt="anjuKish" />
          </div>
          <div className="d-flex d-md-none d-lg-none w-50 w-sm-100  flex-column justify-content-end align-items-end pb-4 position-relative">
            <img className="w-100" src={quote} />
          </div>
          <div
            className="w-45 px-4 px-lg-0 mt-lg-0 mt-4 mx-auto text-lg-start"
            style={{ lineHeight: "1.6" }}
          >
            <h2 children="f-40">
              <b className="f-40" style={{ color: "#F69494" }}>
                LEADING THE UNTABOOERS IS ITS FOUNDER & CEO
              </b>
              <br></br>
              <b className="mt-lg-0 mt-3 primary-blue">ANJU KISH</b>
            </h2>
            <p className="mt-3 fs-5">
              She is a{" "}
              <b className="secondary-b">
                Pioneer in the space of Safety & Sex Education in India
              </b>{" "}
              and is helping drive a movement to end the taboos and start this
              conversation - at home, and in schools.
            </p>
            <ul className="ms-0 ps-0 fs-5" style={{ listStyle: "square" }}>
              <li>
                <b className="secondary-b">Double Graduate</b> in Law & Mass
                Communication.{" "}
              </li>
              <li>
                Certified <b className="secondary-b">Sexuality Educator</b>
              </li>
              <li>
                Author of{" "}
                <b className="secondary-b">award-winning children’s book</b> on
                puberty, sex & growing up titled, ‘How I got my Belly Button’
              </li>
              <li>
                4-time <b className="secondary-b">Tedx Speaker</b>
              </li>
              <li>
                <b className="secondary-b">Blogger</b> empowering parents on
                Safety & Sex Education
              </li>
              <li>
                <b className="secondary-b">Expert Columnist</b> on Safety & Sex
                Education for Tweak India{" "}
              </li>
              <li>
                <b className="secondary-b">Producer of ‘Growing Up’</b>- the
                only Play on Puberty & Safety in India
              </li>
              <li>
                <b className="secondary-b">Stand-up Comic</b> using platforms
                across the globe to change mindsets against Safety & Sex
                Education through humour
              </li>
              <li>
                <b className="secondary-b">Global Ambassador</b> of Parenting
                2.0
              </li>
              <li>
                TOI & SheFest <b className="secondary-b">Woman of Substance</b>{" "}
                Awardee
              </li>

              <li>
                <b className="secondary-b">Advisory Board</b> member of SOBA,
                NMIMS
              </li>
            </ul>
            {/* <p className="fs-5">
              <i>
                “I envision an India where safety & sex education is recognised
                as an important life skill for a child’s holistic development;
                where parents and educators have open and honest conversations
                on puberty, sex, sexuality, relationships, substance abuse &
                safety; Children must grow up with positive information,
                healthy attitudes, & better decision-making skills - all leading
                to a responsible generation and safer society at large.”
              </i>{" "}
              <b>- Anju Kish</b>
            </p> */}
          </div>
        </Container>
      </div>

      <div className="px-4 px-lg-0">
        <Container className="mt-10" style={{ lineHeight: "1.7" }}>
          <h2 className="my-5 primary-blue text-center text-lg-start f-40 ">
            <b>THE UNTABOO ADVISORY BOARD</b>
          </h2>
          <h3 className="text-center mb-5 f-32">
            The Founder's vision is supported by a formidable team of high
            achievers at UnTaboo and an all woman advisory board of industry
            leaders
          </h3>
          <div className="row pt-lg-5 pt-2">
            <div className="col-lg-3 text-center mb-4 mb-lg-0">
              <img className="w-60 text-center text-lg-start" src={ana} />
            </div>
            <div className="col-lg-8 mt-auto text-center text-lg-start">
              <h3 className="primary-blue">
                <b>Anaheeta Goenka</b>
              </h3>
              <h4 className="fs-5">
                <b>Chief Operating Officer, Lowe Lintas</b>
              </h4>
              <p className="fs-5 mt-lg-5 mt-3 mb-0">
                A marketing & communications professional with over 2 decades of
                experience in a host of industries from FMCG to Finance to Food
                to Content across global and local brands. A
                <b className="secondary-b"> behaviour change expert</b> who
                creates effective work that drives change and impacts humanity.
                Awarded across international award platforms like Cannes, WARC
                100, Jay Chiat, Effies, among others. She is also an angel
                investor and a passionate social entrepreneur with an illogical
                love for India.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="mt-lg-5 mt-3  pb-10 px-4 px-lg-0"
        style={{ backgroundColor: "#F3F3F3", lineHeight: "1.7" }}
      >
        <Container className="mt-10">
          <div className="row pt-10 flex-sm-column-reversee">
            <div className="col-lg-8 mt-auto text-center text-lg-start">
              <h3 className="primary-blue">
                <b>Swati Popat Vats</b>
              </h3>
              <h4 className="fs-5">
                <b>
                  President, Podar Education Network<br></br> Early Childhood
                  Assoc & Assoc for Primary Education & Research
                </b>
              </h4>
              <p className="fs-5 mt-lg-5 mt-3 mb-0">
                A passionate teacher & administrator, Dr Swati Popat Vats has
                worked hard to weave innovative methods of learning & teaching.
                She serves as an education consultant and series editor and NEP
                board advisor of the Cambridge Assessment Early Years Program.
                She is also an author who has written 30 books, is a powerful
                blogger, trainer & a{" "}
                <b className="secondary-b">
                  {" "}
                  pioneer of the movement against Child Sexual Abuse{" "}
                </b>{" "}
                in India. She is a true changemaker & an educational activist.
              </p>
            </div>
            <div className="col-lg-3 text-center text-lg-start  mb-4 mb-lg-0">
              <img src={about_img9} />
            </div>
          </div>
        </Container>
      </div>

      <Container className="pb-5" style={{ lineHeight: "1.7" }}>
        <div className="row pt-10 px-4 px-lg-0">
          <div className="col-lg-3 text-center mb-4 mb-lg-0">
            <img
              className="w-100 w-sm-80 text-center text-lg-start"
              src={ratika}
            />
          </div>
          <div className="col-lg-8 mt-auto text-center text-lg-start">
            <h3 className="primary-blue">
              <b>Prof. Ratika Gore</b>
            </h3>
            <h4 className="fs-5">
              <b>Asst. Professor at SPJIMR</b>
            </h4>
            <p className="fs-5 mt-lg-5 mt-3 mb-0">
              A certified coach & trainer, a doctoral scholar, an erstwhile
              entrepreneur & an expert on critical thinking and design thinking,
              Ratika Gore is also a{" "}
              <b className="secondary-b">
                {" "}
                Femina Women Leadership Award winner
              </b>
              . Her articles have been published in Forbes India & Business
              World and her research has been presented at international
              conferences like EMAC. She serves on the advisory boards of NGOs &
              Educational Institutions where she shares her wealth of knowledge
              & expertise across sectors for social good.
            </p>
          </div>
        </div>
      </Container>

      <div
        className="mt-lg-5 mt-3 px-4 px-lg-0 pb-10"
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <Container className="mt-lg-5 mt-3" style={{ lineHeight: "1.7" }}>
          <div className="row pt-10 flex-sm-column-reversee">
            <div className="col-lg-8 mt-auto text-center text-lg-start">
              <h3 className="primary-blue">
                <b>Poonam Kishinchandani</b>
              </h3>
              <h4 className="fs-5">
                <b>Consultant - Personal Branding</b>
              </h4>
              <p className="fs-5 mt-lg-5 mt-3 mb-0">
                A senior{" "}
                <b className="secondary-b">
                  {" "}
                  marketing strategy and communication expert{" "}
                </b>{" "}
                with over 30 years of experience across industries spanning from
                FMCG to Healthcare, Ms Poonam moved away from corporate life to
                bring her wealth of experience to help start-ups and
                entrepreneurs. She pursues her passion for education by honing
                young minds at top business schools. She is a great believer in
                social responsibility and is currently working on projects with
                Rotary International.
              </p>
            </div>
            <div className="col-lg-3 text-center text-lg-start  mb-4 mb-lg-0">
              <img src={about_img11} />
            </div>
          </div>
        </Container>
      </div>

      <Container id={"memoryLane"} className="pt-10">
        <h2 className=" px-4 px-lg-0 primary-blue text-center text-lg-start f-40">
          <b> A GLIMPSE OF OUR WORK... </b>
        </h2>
        <p className="mb-5 text-center text-lg-start px-4 px-lg-0">
          We are the <b className="secondary-b">only company in the world</b>{" "}
          using multiple formats to impart this Education.{" "}
        </p>
        <Workcarousel></Workcarousel>
      </Container>

      <div className="py-5 " style={{ backgroundColor: "#B7c4D1" }}>
        <Container>
          <div className="col-12 text-center py-5 px-4 px-lg-0">
            <h3 className="secondary-blue f-40 fw-bold">
              Come join our movement to UnTaboo safety & sex education and
              create a safer society.{" "}
            </h3>
            <p
              className=" col-lg-9 f-25 col-12 mx-auto py-2 secondary-blue text-center  mt-4 poppins"
              style={{ fontWeight: "600", lineHeight: "1.7" }}
            >
              Volunteer with us I Sponsor our program I Be an affiliate partner
              I Be our brand ambassador I Talk about us I Share your feedback &
              suggestions.
            </p>
            <div
              className=" text-center col-12 mx-auto justify-content-center d-lg-flex flex-wrap "
              style={{ marginTop: "30px" }}
            >
              <Link to="/contact">
                <Button
                  type="button"
                  className="btn btn-light poppins"
                  style={{ backgroundColor: "white", color: "#415094" }}
                >
                  {" "}
                  We would love to hear from you!
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
