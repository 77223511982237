import React, { useState, useEffect, useLayoutEffect } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiFillClockCircle } from "react-icons/ai";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import FormControl from "react-bootstrap/FormControl";

import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";

import { IconContext } from "react-icons";
import { FaRegPlayCircle } from "react-icons/fa";

import { Faq } from "../../organisms/programsFaq";
import discuss from "../../assets/images/dicuss.jpg";
import play from "../../assets/images/playy.jpg";
import bond from "../../assets/images/bondd.png";
import icon_1 from "../../assets/images/age appropriate.png";
import icon_2 from "../../assets/images/designed by experts.png";
import icon_3 from "../../assets/images/assessed learning.png";
import icon_4 from "../../assets/images/full parental control.png";
import icon_5 from "../../assets/images/programs_tick.PNG";
import icon_6 from "../../assets/images/programs_icon6.png";
import icon_7 from "../../assets/images/programs_icon7.png";
import icon_8 from "../../assets/images/programs_icon8.png";
import icon_9 from "../../assets/images/programs_icon9.png";
import img_1 from "../../assets/images/neel_kiara.jpg";
import img_2 from "../../assets/images/Author-Dr-Anjali-Chhabria.jpg";
import img_3 from "../../assets/images/program_watch.jpg";
import { PfgCarousel } from "../../organisms/pfg-carousel";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";

import ModalVideo from "react-modal-video";
import Mvideo from "../../atoms/ModalVideo";

const InfoSpan = styled.span `
  font-size: 12px;
  color: gray;
`;

const UntabooButton = styled.button `
  background: #6bcacf;
  font-weight: 600;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  padding: 10px 30px;
  &:hover {
    background-color: #0bb7af;
    border: none;
    box-shadow: 0px 10px 30px 0px rgb(166 223 226 / 20%);
    background: linear-gradient(120deg, #6bcacf 0%, #b1e3e5 100%);
    color: #fff !important;
  }
  ${(props) =>
    props.secondary
      ? css`
          color: #0c2e60;
          border: 1px solid #0c2e60;
          background-color: white;
          transition: all 0.4s ease-in-out;
        `
      : ""}
  &:hover {
    color: #fff;
  }
`;
export const PFGLandingPage = (props) => {
  const [isOpen, setOpen] = useState(false);

  function filterProcesses(e) {
    const processes = document.querySelectorAll(".processess div");
    const active = document.querySelectorAll(".process_button .p-cursor"); // select all animal divs
    let filter = e.target.dataset.filter; // grab the value in the event target's data-filter attribute
    processes.forEach((process) => {
      process.classList.contains(filter) // does the animal have the filter in its class list?
        ? process.classList.remove("hidden")
        : // if yes, make sure .hidden is not applied
        process.classList.add("hidden"); // if no, apply .hidden
    });
    let activefilter = e.target.dataset.filter;
    active.forEach((values) => {
      // values.classList.contains(filter)
      values.classList.contains(activefilter)
        ? values.classList.add("processactive")
        : values.classList.remove("processactive");

      // values.classList.add("processactive");
    });
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const params = useParams();
  let user;

  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  const [program, setProgram] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enroll, setEnroll] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const [eError, seteError] = useState(null);
  const [learners, setLearners] = useState([]);
  const [lLoading, setlLoading] = useState(true);
  const [lError, setlError] = useState(null);
  const [transLoading, setTransLoading] = useState(false);
  const [transError, setTransError] = useState(null);
  const [hasGift, setHasGift] = useState(false);
  const [giftCodeTouched, setGiftCodeTouched] = useState(false);
  const [giftCode, setGiftCode] = useState(null);

  useEffect(() => {
    app_api
      .get("programs/public/" + props.slug)
      .then((res) => {
        setLoading(false);
        setProgram(res.data);
        document.title = `UnTaboo | ${res.data?.name || "Program"}`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
    if (isLoggedIn) {
      app_api
        .get(`learner/parent/${user?.id}`)
        .then((res) => {
          setlLoading(false);
          setLearners(res.data);
        })
        .catch((err) => {
          setlLoading(false);
          setlError(err?.response?.data?.message || "Error");
        });
    }
  }, [props.slug]);

  const enrollClick = () => {
    if (isLoggedIn) {
      // setEnroll(true);
      navigate(`/programs?program_id=${program?.id}`)
    } else {
      navigate(`/login?next_to=${program?.id}`);
    }
  };

  const hasGiftCoupon = () => {
    setHasGift(true);
  };

  const doesNotHaveGiftCoupon = () => {
    setHasGift(false);
    setGiftCodeTouched(false);
    setGiftCode(null);
  };

  const giftCodeChange = (e) => {
    setGiftCode(e.target.value);
  };

  const redeemGift = () => {
    navigate(`/gift/redeem/code/${giftCode}`);
  };

  const giftClick = () => {
    navigate("gift");
  };

  const enrollClose = () => setEnroll(false);
  const dismissEnrollError = () => seteError(null);
  const dismissLearnerError = () => setlError(null);
  const dismissTransError = () => setTransError(null);

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section className="poppins">
      <div className="position-relative">
        <div
          style={{
            backgroundImage:
              "linear-gradient(90deg, rgba(255,210,104,1) 40%, rgba(250,217,140,1) 70%)",
            color: "white",
          }}
        >
          <Container>
            <div className="" id="enroll">
              <div className="pt-10 col-lg-6 px-4 px-lg-0">
                <h1 className="f-55">
                  <b className="f-55 f-sm-38 Yanone_Kaffeesatz-700">
                    {program?.name}
                  </b>
                </h1>
                <p className=" mt-4" style={{ fontWeight: "600" }}>
                  {program?.description}
                </p>
              </div>
              <div className="col-lg-4 d-flex px-4 px-lg-0">
                <IconContext.Provider value={{ color: "white", size: "30px" }}>
                  <div className="fs-5 pe-3 pe-lg-0 d-flex align-items-center">
                    <FaRegPlayCircle className="me-2" />
                    {program?.level_sequence?.length - 3}&nbsp;Videos
                  </div>
                  <div className="fs-5 ps-lg-5 ps-0 d-flex align-items-center">
                    <AiFillClockCircle className="me-2" />
                    {program?.learning_hours}
                    &nbsp;Hours
                  </div>
                </IconContext.Provider>
              </div>
              <div className="col-lg-4 d-flex py-4 px-4 px-lg-0">
                <button
                  type="button"
                  className="btn btn-light px-4 fw-bold"
                  style={{ backgroundColor: "white" }}
                  onClick={enrollClick}
                >
                  Buy Now
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light px-4 ms-3 fw-bold"
                  style={{ border: "1px solid white", color: "black" }}
                  onClick={giftClick}
                >
                  Gift this program
                </button>
              </div>
              <div className="pb-10 position-relative mt-3 px-4 px-lg-0">
                <p
                  className="fs-5 animate__animated animate__shakeX animate__slower	animate__infinite"
                  style={{ fontWeight: "600" }}
                >
                  <i>Experience the Program</i>
                </p>
                <div
                  className="video-play blue_shadow rounded-circle position-absolute  d-flex justify-content-center align-items-center"
                  onClick={() => {
                    navigate(`/program/${params.slug}/demo`);
                  }}
                >
                  <div
                    style={{
                      width: "0",
                      height: "0",
                      borderTop: "12px solid transparent",
                      borderBottom: "12px solid transparent",
                      borderLeft: "12px solid white",
                    }}
                  ></div>
                </div>
                <i>
                  {hasGift ? (
                    <Row className="g-2">
                      <Col sm="4">
                        <FormControl
                          value={giftCode}
                          onChange={giftCodeChange}
                          onBlur={() => setGiftCodeTouched(true)}
                          placeholder="Enter code"
                          isValid={giftCodeTouched && !!giftCode}
                          isInvalid={giftCodeTouched && !giftCode}
                        />
                      </Col>
                      <Col>
                        <Button
                          variant="primary"
                          className="me-2"
                          onClick={redeemGift}
                          disabled={!giftCode}
                        >
                          Enter
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={doesNotHaveGiftCoupon}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <span
                      onClick={hasGiftCoupon}
                      className="fs-6 text-decoration-underline"
                      style={{ color: "black" }}
                    >
                      Have a gift coupon ?
                    </span>
                  )}
                </i>
              </div>
            </div>
          </Container>
        </div>
        <Container>
          <div className="secondary-blue col-lg-6 my-5 px-4 px-lg-0">
            <h1 className="fs-1 Yanone_Kaffeesatz-700">
              Is Puberty Adventure for my Daughter?
            </h1>
            <p className="fs-5 py-4 text-justify">
              Puberty can be a difficult and tricky phase of growing up for your
              daughter.
              <br></br>
              <br></br>
              Hormonal shifts at this stage may cause her to become snappy or
              rude or suddenly turn quiet, and you can see her struggling to
              deal with it. Mood swings could be confusing and physical pubertal
              changes like periods and breast development could be embarrassing.
              <br></br>
              <br></br>
              As a parent, you can help her navigate this period of adolescent
              health with ease by getting her ready for puberty before it hits!
              <br></br>
              <br></br>
              This program educates your daughter about puberty in a way that is
              fun and factual, normalising changes and sowing the seeds of
              confidence, body positivity and gender sensitivity.
              <br></br>
              <br></br>
              It helps deepen the parent-child partnership to make puberty a
              stress free, enjoyable experience for your daughter and a
              celebration for both of you.
            </p>
          </div>

          <div className="enrollmentCard col-lg-5 col-12 mb-10 ">
            <div className="col-12 col-lg-8 position-relative">
              <img className="col-12" src={img_1} />

              <Mvideo
                channel="custom"
                isOpen={isOpen}
                url="https://api.untaboo.online/uploads/FILM_21_Landing.m3u8"
                onClose={() => setOpen(false)}
              />
              <button
                onClick={() => setOpen(true)}
                className="video-play blue_shadow rounded-circle position-absolute top-50   d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#6BCACF",
                  width: "16%",
                  height: "15%",
                  left: "43%",
                }}
              >
                <div
                  className=""
                  style={{
                    width: "0",
                    height: " 0",
                    borderTop: "12px solid transparent",
                    borderBottom: "12px solid transparent",
                    borderLeft: "12px solid white",
                  }}
                ></div>
              </button>
            </div>
            <div
              className="col-12 col-lg-8 px-3 py-3"
              style={{ border: "1px solid #edeff2", backgroundColor: 'white' }}
            >
              <div className="px-3 pt-3 d-flex flex-wrap col-12">
                <div className="w-50 text-start">No. of Videos</div>
                <div className=" w-50 text-end">
                  {program?.level_sequence?.length - 3}
                </div>
              </div>
              <hr />
              <div className="px-3 d-flex flex-wrap col-12">
                <div className="w-50 text-start">Total learning Hours</div>
                <div className="w-50 text-end">
                  {program?.learning_hours} hrs
                </div>
              </div>
              <hr />
              <div className="px-3 d-flex flex-wrap col-12">
                <div className="w-50 text-start">Program creator</div>
                <div className="w-50 text-end">{program?.creator}</div>
              </div>
              <hr />
              <div className="px-3 d-flex flex-wrap col-12">
                <div className="w-50 text-start fs-6">Age group</div>
                <div className="w-50 text-end fs-6">
                  {program?.age_group} Years(Girls)
                </div>
              </div>
              <hr />
              <div className="px-3 d-flex flex-wrap col-12">
                <div className="w-50 text-start fw-bold">
                  <h4 className="fs-6 fw-bold">Program Fee</h4>
                </div>
                <div className="w-50 text-end fw-bold">
                  <h4 className="fs-6 fw-bold">
                    {" "}
                    <span
                      className="pe-2"
                      style={{
                        color: "grey",
                        fontSize: "15px",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹ 2200.00
                    </span>
                    Rs.{program?.cost}
                  </h4>
                </div>
              </div>
              <button
                type="button "
                className="btn btn-danger fw-bold col-12 mt-4 mb-3 p-2"
                style={{ backgroundColor: "#F64E60", color: "white" }}
                onClick={enrollClick}
              >
                Buy Now
              </button>
              <button
                type="button "
                className="btn btn-danger fw-bold col-12 mb-4 p-2"
                style={{
                  backgroundColor: "#FFE2E5",
                  color: "#F64E60",
                  border: "none",
                }}
                onClick={giftClick}
              >
                Gift This Program
              </button>
            </div>
          </div>

          <div className="col-lg-6  d-lg-flex flex-lg-wrap  justify-content-between px-4 px-lg-0">
            <div className="col-lg-5 col-12 mb-lg-5">
              <img className="w-20" src={icon_1} />
              <h3 className="primary-blue fs-4 fw-bold mt-3">
                Age Appropriate
              </h3>
              <p style={{ fontSize: "16px", color: "#656565" }}>
                The information is relevant to the age group and doesn’t include
                any sexual material
              </p>
            </div>
            <div className=" col-lg-5 col-12">
              <img className="w-20" src={icon_2} />
              <h3 className="primary-blue fs-4 fw-bold  mt-3">
                Designed By Expert
              </h3>
              <p style={{ fontSize: "16px", color: "#656565" }}>
                Crafted by leaders in the field of child pubertal health and
                well-being.
              </p>
            </div>
            <div className=" col-lg-5 col-12">
              <img className="w-20" src={icon_3} />
              <h3 className="primary-blue fs-4 fw-bold mt-3">
                Measured Learning
              </h3>
              <p style={{ fontSize: "16px", color: "#656565" }}>
                Each level includes pre &amp; post-assessments to track progress
              </p>
            </div>
            <div className="col-lg-5 col-12">
              <img className="w-20" src={icon_4} />
              <h3
                className="primary-blue fs-4 fw-bold "
                style={{ marginTop: "1.3rem" }}
              >
                Full Parental Control
              </h3>
              <p style={{ fontSize: "16px", color: "#656565" }}>
                The parent can monitor every step of the journey
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="col-12 mt-10  pb-10"
        style={{ backgroundColor: "#26547C" }}
      >
        <Container>
          <h2 className="f-40 text-white text-center fw-bold pt-10 mb-5">
            Heart-Warming Feedback
          </h2>
          <PfgCarousel></PfgCarousel>
        </Container>
      </div>
      <div className="bg-img-programs text-center pt-10 mt-5 ">
        <Container>
          <h1 className="primary-blue Yanone_Kaffeesatz-700 f-50 px-4 px-lg-0">
            Let the Adventure begin!
          </h1>
          <p
            className="col-lg-10 mx-auto fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0"
            style={{ color: "#5C6C7E" }}
          >
            Experts have created this Puberty education program in an adventure
            format to ensure learning is enjoyable and effective. Every change
            experienced during puberty has been created like a ‘level’ on this
            exciting journey to the Puberty Peak. The next level unlocks on
            completing the current level, just like in a game.
          </p>
          <h3
            className="mt-5  fw-bold px-4 px-lg-0"
            style={{ color: "#5C6C7E", fontSize: "25px" }}
          >
            There are 9 levels in this program. Let the adventure begin!
          </h3>
          <div className="d-flex flex-lg-row flex-column justify-content-between pt-lg-5 pt-3 px-4 px-lg-0">
            <div className="col-lg-6 col-12">
              <Accordion className=" mb-3">
                <h3 className="primary-blue">
                  <b className="fs-4">Levels</b>
                </h3>
                {program?.levels
                  ?.filter((l) => l?.type === "level")
                  .sort(
                    (a, b) =>
                      program?.level_sequence?.indexOf(a.id) -
                      program?.level_sequence?.indexOf(b.id)
                  )
                  .map((l, index) => (
                    <Accordion.Item
                      key={index}
                      eventKey={index}
                      className="accordion-item mt-4"
                    >
                      <Accordion.Header style={{ color: "#1e4363" }}>
                        Level {index + 1}: {l?.title}
                      </Accordion.Header>
                      <Accordion.Body style={{ textAlign: "left" }}>
                        {l?.description}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
            <div
              className="col-lg-4 card px-3 pb-3"
              style={{
                backgroundColor: "#C9F7F5",
                border: "none",
                borderRadius: "20px",
                objectFit: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="p-4 text-start">
                <h3 className="primary-blue ">
                  <b className="fs-3">Key Learnings</b>
                </h3>
                <div className="mt-3 d-flex flex-wrap align-items-center pt-3">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 "
                    style={{ fontWeight: "600" }}
                  >
                    Handling changes positively
                  </p>
                </div>
                <div className=" d-flex flex-wrap align-items-center">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 fs-6"
                    style={{ fontWeight: "600" }}
                  >
                    Managing Peer Pressure
                  </p>
                </div>
                <div className=" d-flex flex-wrap align-items-center">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 fs-6"
                    style={{ fontWeight: "600" }}
                  >
                    Gender sensitisation
                  </p>
                </div>
                <div className=" d-flex flex-wrap align-items-center">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 fs-6"
                    style={{ fontWeight: "600" }}
                  >
                    Emotional awareness
                  </p>
                </div>
                <div className=" d-flex flex-wrap align-items-center">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 fs-6"
                    style={{ fontWeight: "600" }}
                  >
                    Body confidence
                  </p>
                </div>
                <div className=" d-flex flex-wrap align-items-center">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p
                    className="primary-blue  mb-0 p-17 fs-6"
                    style={{ fontWeight: "600" }}
                  >
                    Bonding with parents
                  </p>
                </div>
              </div>
              <div
                className="mt-3 py-3 px-2 row"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-4">
                  <img className="col-12" src={img_2} />
                </div>
                <div className="col-8 text-start">
                  <div
                    className="text-center fst-italic animate__animated animate__heartBeat animate__slower animate__infinite	"
                    style={{ backgroundColor: "#F64E60", color: "white" }}
                  >
                    <h3 className="fs-6 p-1">Expert Recommendation</h3>
                  </div>
                  <h3 className="fs-5 secondary-blue fw-bold">
                    Dr. Anjali Chhabria
                  </h3>
                  <h4 style={{ fontSize: "13px" }}>
                    Psychiatrist &amp; Psychotherapist
                  </h4>
                </div>
                <div className=" text-start mt-3">
                  <p className=" mb-0 p-17" style={{ fontSize: "16px" }}>
                    "It is crucial to prepare our children for the plethora of
                    changes that puberty brings with and it is important to do
                    it before these changes start. They need to be told what to
                    expect and how they can navigate the physical, emotional,
                    behavioural changes of puberty and the shift in
                    interpersonal relationships. It has been proven that this
                    leads to positive behaviours and stronger relationships with
                    parents. I feel it's important for parents to partner with
                    organisations like UnTaboo and help their child cope with
                    what may be a difficult time."
                  </p>
                </div>
              </div>
              <div className="col-12 mt-3 text-center">
                <UntabooButton onClick={enrollClick}>Sign me Up</UntabooButton>
              </div>
            </div>
          </div>
        </Container>
        <hr className="w-100 my-10"></hr>

        <div className="bg-img-pfb">
          <Container>
            <div className="d-lg-flex flex-lg-wrap ">
              <div className="col-12 px-4 px-lg-0">
                <h1 className=" mb-4 secondary-blue fw-bold Yanone_Kaffeesatz-700 f-50">
                  THE PROCESS
                </h1>
                <h4 className="mb-3 f-35" style={{ fontWeight: "600" }}>
                  Engaging Format
                </h4>
                <p className="mt-3 fs-5 pb-4">
                  Our child-friendly Puberty program is age appropriate &
                  designed to cater to the developmental needs of a child at
                  this age. It has been designed in an engaging manner by
                  experts using a unique ‘<b>Watch</b> ~ <b>Learn</b>~{" "}
                  <b>Discuss</b> ~ <b>Play</b> &amp;
                  <b> Bond</b>’ method of learning. It can be consumed
                  independently by the child from the comfort and safety of
                  their home, leaving them with takeaways that are easy to
                  absorb and practical to follow - all helping the child grow up
                  healthy, happy, and safe with responsible attitudes.
                </p>
              </div>
              <div className="col-lg-6 text-start process_button px-4 px-lg-0">
                <div
                  data-filter="watch"
                  onClick={filterProcesses}
                  className="watch mt-5 col-lg-10 p-3 p-cursor  align-center d-flex flex-row rounded-3 processactive"
                >
                  <div
                    className="col-3 my-auto"
                    data-filter="watch"
                    onClick={filterProcesses}
                  >
                    <img
                      className="col-8"
                      src={icon_6}
                      data-filter="watch"
                      onClick={filterProcesses}
                    />
                  </div>
                  <div
                    className=" col-9"
                    data-filter="watch"
                    onClick={filterProcesses}
                  >
                    <h3
                      className="fs-5 fw-bold"
                      data-filter="watch"
                      onClick={filterProcesses}
                    >
                      Watch &amp; Learn - anytime, anywhere
                    </h3>
                    <p
                      className="fs-6"
                      style={{ color: "#6c757d" }}
                      data-filter="watch"
                      onClick={filterProcesses}
                    >
                      Lessons are taught through videos to engage &amp; then
                      reiterated through text to maximise retention.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto d-sm-flex d-lg-none d-md-none processess">
                  <div className="watch rounded-3 shadow-sm p-3 mb-5 bg-body rounded show">
                    <img className="col-12" src={img_3} />
                  </div>
                </div>

                <div
                  data-filter="discuss"
                  onClick={filterProcesses}
                  className="discuss mt-3 col-lg-10 p-3 p-cursor align-center rounded-3 d-flex flex-row"
                >
                  <div
                    className="col-3 my-auto"
                    data-filter="discuss"
                    onClick={filterProcesses}
                  >
                    <img
                      className="col-8 "
                      src={icon_7}
                      data-filter="discuss"
                      onClick={filterProcesses}
                    />
                  </div>
                  <div
                    className=" col-9"
                    data-filter="discuss"
                    onClick={filterProcesses}
                  >
                    <h3
                      className="fs-4 fw-bold "
                      data-filter="discuss"
                      onClick={filterProcesses}
                    >
                      Discuss - anytime, anywhere{" "}
                    </h3>
                    <p
                      className="fs-6 "
                      style={{ color: "#6c757d" }}
                      data-filter="discuss"
                      onClick={filterProcesses}
                    >
                      Any doubts arising post the lesson are clarified through a
                      FAQs, normalising changes &amp; differences.
                    </p>
                  </div>
                </div>
                <div className="">
                  <div className="col-lg-6 my-auto d-sm-flex d-lg-none d-md-none processess">
                    <div className="discuss rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                      <img className="col-12" src={discuss} />
                    </div>
                  </div>
                </div>

                <div
                  data-filter="play"
                  onClick={filterProcesses}
                  className="play p-cursor  mt-3 col-lg-10 p-3 align-center rounded-3 d-flex flex-row"
                >
                  <div
                    className="col-3 my-auto"
                    data-filter="play"
                    onClick={filterProcesses}
                  >
                    <img
                      className="col-8"
                      src={icon_8}
                      data-filter="play"
                      onClick={filterProcesses}
                    />
                  </div>
                  <div
                    className=" col-9"
                    data-filter="play"
                    onClick={filterProcesses}
                  >
                    <h3
                      className="fs-4 fw-bold"
                      data-filter="play"
                      onClick={filterProcesses}
                    >
                      Play - anytime, anywhere{" "}
                    </h3>
                    <p
                      className="fs-6"
                      style={{ color: "#6c757d" }}
                      data-filter="play"
                      onClick={filterProcesses}
                    >
                      Gamified evaluation to check learnings &amp; reward the
                      child while generating a progress report for the parent.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto d-sm-flex d-lg-none d-md-none processess">
                  <div className="play rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                    <img className="col-12" src={play} />
                  </div>
                </div>

                <div
                  data-filter="bond"
                  onClick={filterProcesses}
                  className="bond p-cursor  mt-3 col-lg-10 p-3 align-center rounded-3 d-flex flex-row"
                >
                  <div
                    className="col-3 my-auto"
                    data-filter="bond"
                    onClick={filterProcesses}
                  >
                    <img
                      className="col-8"
                      src={icon_9}
                      data-filter="bond"
                      onClick={filterProcesses}
                    />
                  </div>
                  <div
                    className=" col-9"
                    data-filter="bond"
                    onClick={filterProcesses}
                  >
                    <h3
                      className="fs-4 fw-bold"
                      data-filter="bond"
                      onClick={filterProcesses}
                    >
                      Bond - anytime, anywhere
                    </h3>
                    <p
                      className="fs-6"
                      style={{ color: "#6c757d" }}
                      data-filter="bond"
                      onClick={filterProcesses}
                    >
                      A fun Parent-child activity at the end of program to break
                      the ice &amp; help parents to take this conversation
                      forward.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto d-sm-flex d-lg-none d-md-none processess">
                  <div className="bond rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                    <img className="col-12" src={bond} />
                  </div>
                </div>
              </div>

              <div className="col-lg-6 d-none d-lg-flex d-md-flex my-auto processess">
                <div className="watch rounded-3 shadow-sm p-3 mb-5 bg-body rounded">
                  <img className="col-12" src={img_3} />
                </div>
                <div className="discuss rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                  <img className="col-12" src={discuss} />
                </div>
                <div className="play rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                  <img className="col-12" src={play} />
                </div>
                <div className="bond rounded-3 shadow-sm p-3 mb-5 bg-body rounded hidden">
                  <img className="col-12" src={bond} />
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="pb-10 pt-10 " style={{ backgroundColor: "#B7c4D1" }}>
          <Container>
            <div className="col-lg-12 text-center col-12 px-4 px-lg-0">
              <h3 className="secondary-blue text-center  fs-1 fw-bold">
                Hey UnTabooer
              </h3>
              <p
                className="col-12 text-center col-lg-7 secondary-blue mx-auto mt-4 poppins"
                style={{
                  lineHeight: "1.7",
                  fontWeight: "600",
                }}
              >
                Ready to join thousands of parents across India, to partner with
                your kid in turning puberty education into an adventure?
              </p>
              <div className="mt-5  text-center col-12  ">
                <UntabooButton
                  onClick={enrollClick}
                  type="button"
                  variant="success"
                  className="btn btn-green poppins mb-lg-0 mb-3 rounded-3"
                  style={{ backgroundColor: "#6BCACF", color: "#fff" }}
                >
                  Get Started Now
                </UntabooButton>
              </div>
            </div>
          </Container>
        </div>
        <div
          className=" pb-10 text-center"
          style={{ backgroundColor: "#F69494 " }}
        >
          <h2 className="pt-10 px-2 text-white f-35">
            FREQUENTLY ASKED QUESTIONS
          </h2>
          <p
            className="mb-5 fs-5 "
            style={{ color: "#1e4363", fontWeight: "600" }}
          >
            Got doubts? We got it covered!
          </p>
          <div className="col-11 col-lg-10 mx-auto d-lg-flex justify-content-around">
            <div className="w-75 w-sm-100 px-2 px-lg-0">
              <Faq />
            </div>
          </div>
        </div>
      </div>
      <Container>

      </Container>
    </section>
  );
};